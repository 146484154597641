import React from "react";
import { Grid } from "@mui/material";
import "./infodetails.css";
import Loglimit from "../../assets/infodetails/onee.png";
import Analytics from "../../assets/infodetails/twoo.png";
import Cybercrime from "../../assets/infodetails/three.png";
import Expertics from "../../assets/infodetails/fourr.png";
import Logs from "../../assets/infodetails/logs.png";
import Analyticss from "../../assets/infodetails/analytics.png";
import Cyber from "../../assets/infodetails/cyber.png";
import Experticss from "../../assets/infodetails/expertise.png";
import TitleBackground from "./TitleBackground";

export default function Infodetails() {
  let arr = [
    {
      iconUrl: Logs,
      title: "Log Without Limits",
      content:
        "Simple yet powerful techniques for prioritizing logs and staying within the budget–without manual backups or dropping data.",
      imgUrl: Loglimit,
    },
    {
      iconUrl: Analyticss,
      title: "Intelligent Analytics",
      content:
        "A set of interactive, customized data from various sources to make data-driven decisions through integration with the BI platform.",
      imgUrl: Analytics,
    },
    {
      iconUrl: Cyber,
      title: "Compliance​ & Cyber Crime",
      content:
        "Gathering and managing IPDR subscriber or user  logs. Provide the data that forms the foundation of Law Enforcement Agencies investigation(s).",

      imgUrl: Cybercrime,
    },
    {
      iconUrl: Experticss,
      title: "Years of Expertise",
      content:
        "Over 20 years of proficiency in the field of log management with some of the world’s most demanding public networks in telecom.",
      imgUrl: Expertics,
    },
  ];
  return (
    <section className="infodetails-sec">
      <div className="section-caption-info">
        <TitleBackground title={<h2>Why Keylong?</h2>} />
      </div>

      <br />
      <div className="infodetails" alignItems="center" justifyContent="center">
        <Grid
          className="MuiGrid-container-info"
          container
          alignItems="center"
          justifyContent="center"
        >
          {arr.map((entity, i) => {
            return (
              <Grid
                item
                sm={3}
                key={i}
                sx={{
                  backgroundImage: `url(${entity.imgUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundPositionX: "right",
                }}
              >
                <Grid container alignItems="left" justifyContent="left">
                  <div className="icon-outer-box">
                    <img alt="infodetails" src={entity.iconUrl} />
                  </div>

                  <div className="section-heading">
                    <p
                      style={{
                        fontWeight: "600",
                        color: "#00327E",
                        fontSize: "18px",
                        fontStyle: "medium",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {entity.title}
                      <p
                        style={{
                          fontWeight: "200",
                          color: "#00327E",
                          fontSize: "14px",
                          marginTop: "10px",
                          lineHeight: "22px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {entity.content}
                      </p>
                    </p>
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </section>
  );
}
