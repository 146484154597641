import React from "react";
import "./Services.css";
import { Grid } from "@material-ui/core";
import Usecase from "../../assets/services/usecases.png";
import TitleBackground from "../homepage/TitleBackground";
export default function Services() {
  return (
    <section className="section-service">
      <div className="container">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div
              className="section-design"
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                padding: "156px 24px",
              }}
            >
              <TitleBackground
                title={<h1 className="section-service-title">Use Cases</h1>}
              />

              <p className="service-desc">
                Our years of experience and skills enable us to offer quality
                services to businesses of all sizes and to different industries.
              </p>
            </div>
          </Grid>

          <Grid item xs={6}>
            <img src={Usecase} className="service-img" alt="section bg" />
          </Grid>
        </Grid>
      </div>

      <div></div>
    </section>
  );
}
