import React from "react";
import "./Overview.css";
import sction from "../../assets/intro/sections.png";
import TitleBackground from "./TitleBackground";

export default function Overview() {
  return (
    <section className="section-overview">
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "left",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <div style={{ width: "50%" }}>
          <TitleBackground
            title={
              <h1 className="section-overview-title">
                SaaS Solution That Scales Without Limits
              </h1>
            }
          />

          <p className="overview-desc">
            KeyLong Logging Platform supports a modular approach to log
            ingestion allowing plug-ins for accepting log formats including
            Syslog, IPFIX, and NetFlow-9 which are commonly used by routers,
            firewalls, switches, CGN (CGNAT) systems, Broadband Network Gateways
            (BNG), and Wi-Fi controllers. Multiple log collectors may be used
            and they can be scaled linearly.
          </p>
        </div>
        <div>
          <img src={sction} className="overview-img" alt="section bg" />
        </div>
      </div>

      <div></div>
    </section>
  );
}
