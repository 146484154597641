import React, { useState } from "react";
import "./Products.css";
import Cloudlogin1 from "../../assets/products/cloudlogin1.png";
import Cloudlogin2 from "../../assets/products/cloudlogin2.png";
import Cloudlogin3 from "../../assets/products/cloudlogin3.png";
import Cloudlogin4 from "../../assets/products/cloudlogin4.png";
import Cloudlogin5 from "../../assets/products/cloudlogin5.png";
import Cloudlogin6 from "../../assets/products/cloudlogin6.png";
import Featuers1 from "../../assets/featuers/featuers1.png";
import Featuers2 from "../../assets/featuers/featuers2.png";
import Featuers3 from "../../assets/featuers/featuers3.png";
import Featuers4 from "../../assets/featuers/featuers4.png";
import Featuers5 from "../../assets/featuers/featuers5.png";
import Featuers6 from "../../assets/featuers/featuers6.png";

import { Grid, Modal, Typography } from "@mui/material";
import TitleBackground from "./TitleBackground";
import { Box } from "@mui/system";

export default function Products() {
  const [open, setOpen] = useState(false);
  const [textToRender, setTextToRender] = useState([]);
  const handleClose = () => setOpen(false);
  const [title, setTilte, setIcon] = useState("");

  let arr = [
    {
      title: "Cloud Logging",
      imgUrl: Cloudlogin1,
      icon: Featuers1,
      text: [
        <ul className="nav-links">
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Collect, aggregate, and store logs from any data source
            </div>
          </li>{" "}
          <li className="nav-link-li">
            <span className="dot"></span>{" "}
            <div className="text-details">
              Automated log parsing for telecom subscriber identity
            </div>{" "}
          </li>{" "}
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Real-time log monitoring and analysis
            </div>{" "}
          </li>{" "}
        </ul>,
      ],
    },
    {
      title: "Log Without Limits",
      imgUrl: Cloudlogin3,
      icon: Featuers2,
      text: [
        <ul className="nav-links">
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Ingest everything, index, and retain what you need using intuitive
              filters{" "}
            </div>
          </li>
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Very high transactions (logline) per second{" "}
            </div>
          </li>
          <li className="nav-link-li">
            {" "}
            <span className="dot"></span>
            <div className="text-details">
              {" "}
              Retrieve archived logs when you need them{" "}
            </div>{" "}
          </li>{" "}
        </ul>,
      ],
    },
    {
      title: "Multi-Vendor Support",
      imgUrl: Cloudlogin5,
      icon: Featuers3,
      text: [
        <ul className="nav-links">
          {" "}
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Over 200 attributes are natively understood by Keylong{" "}
            </div>
          </li>
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Additional standard/vendor-specific attributes can be added{" "}
            </div>
          </li>
          <li className="nav-link-li">
            <span className="dot"></span>{" "}
            <div className="text-details">
              Attributes can be applied to perform context-specific searches and
              analytics
            </div>{" "}
          </li>
        </ul>,
      ],
    },
    {
      title: "NAT Log Collector Engine",
      imgUrl: Cloudlogin4,
      icon: Featuers4,
      text: [
        <ul className="nav-links">
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              With over 20 Syslog RFC standard formats for NAT
            </div>
          </li>
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Ready for use in IPv6 transition and IPv4 conservation{" "}
            </div>
          </li>{" "}
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              NAT to subscriber co-relation, useful in cybercrime cases{" "}
            </div>
          </li>{" "}
        </ul>,
      ],
    },
    {
      title: "Dashboard & Analysis",
      imgUrl: Cloudlogin2,
      icon: Featuers5,
      text: [
        <ul className="nav-links">
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Allows configuration of devices, users, flow rules & data stores
            </div>{" "}
          </li>{" "}
          <li className="nav-link-li">
            <span className="dot"></span>{" "}
            <div className="text-details">
              Analytics to store, retrieve, archive, and index large volumes of
              flow data
            </div>{" "}
          </li>
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Supports both real-time and non-real-time analytics
            </div>{" "}
          </li>{" "}
        </ul>,
      ],
    },
    {
      title: "Troubleshooting",
      imgUrl: Cloudlogin6,
      icon: Featuers6,
      text: [
        <ul className="nav-links">
          <li className="nav-link-li">
            <span className="dot"></span>
            <div className="text-details">
              Easy search, filtering, and sorting through thousands of log
              entries{" "}
            </div>
          </li>{" "}
          <li className="nav-link-li">
            {" "}
            <span className="dot"></span>
            <div className="text-details">
              {" "}
              Detect and troubleshoot issues with logs{" "}
            </div>
          </li>
          <li className="nav-link-li">
            {" "}
            <span className="dot"></span>
            <div className="text-details">
              Visualize and explore collected logs using intuitive graphs and
              analytics{" "}
            </div>{" "}
          </li>
        </ul>,
      ],
    },
  ];

  const style = {
    position: "absolute",
    borderRadius: "20px",
    backgroundColor: "#ffff",
    top: "50%",
    left: "50%",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    width: 400,
    fontSize: "14px",
    border: "none",
    p: 4,
  };
  return (
    <section className="products-sec">
      <div>
        <div className="product-section-caption">
          <TitleBackground title="Features" />
        </div>
        <br />

        <div className="section-products flex-center">
          {arr.map((entity, i) => {
            return (
              <div
                className="product"
                key={i}
                onClick={() => {
                  setTextToRender(entity.text);
                  setOpen(true);
                  setTilte(entity.title);
                  setIcon(entity.icon);
                }}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  height="inherit"
                >
                  <Grid item xs={5}>
                    <div className="product-outer-box">
                      <img alt="Product" src={entity.imgUrl} />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <p
                      style={{
                        fontWeight: "600",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      {entity.title}
                    </p>
                  </Grid>
                </Grid>
              </div>
            );
          })}
          <div className="cardbox">
            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    fontSize: "25px",
                    fontFamily: "Merriweather",
                    backgroundColor: "#300327E",
                    gap: "6px",
                    fontWeight: "400px",
                    color: "#00327E",
                    borderradius: "20px",
                    alignItems: "center",
                  }}
                >
                  <div className="product-outer-box">{title}</div>
                </Typography>
                {textToRender?.map((e, i) => {
                  return (
                    <Typography
                      key={i}
                      id="modal-modal-description"
                      sx={{ mt: 2 }}
                      style={{
                        fontSize: "18px",
                        fontFamily: "Popins",
                        color: "#00327E",
                        fontWeight: "600px",
                        gap: "6px",
                        alignItems: "center",
                        alignContent: "center",
                        lineHeight: "22px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
}
