import React from "react";

export default function TitleBackground({ title }) {
  return (
    <React.Fragment>
      <div style={{ position: "relative", display: "block" }}>
        <span
          style={{
            position: "absolute",
            height: "120px",
            width: "120px",
            borderRadius: "50%",
            backgroundColor: "#CBCDCC",
            bottom: ".2%",
            top: "-30%",
            display: "block",
            left: "10%",
            opacity: ".25",
          }}
        ></span>
        <div
          style={{
            color: "#00327E",
            fontSize: "46px",
            fontStyle: "Popins",
            fontWeight: 600,
          }}
        >
          {title}
        </div>
      </div>
    </React.Fragment>
  );
}
