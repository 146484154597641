import React from "react";
import "./Intro.css";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Graph from "../../assets/intro/graph.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function Intro() {
  const [demoHoverIcon, setDemoHoverIcon] = React.useState(
    <ArrowForwardIosIcon />
  );
  const [startedHoverIcon, setStartedHoverIcon] = React.useState(
    <ArrowForwardIosIcon />
  );
  return (
    <section className="slider-sec">
      <div
        className="section-image"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          className="section-design"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            padding: "0px 80px",
          }}
        >
          <h1
            style={{
              color: "white",
              fontFamily: "Popins",
              fontSize: "45px",
              lineHeight: "30px",
            }}
          >
            Lawful Logging & Analytics
          </h1>

          <p
            style={{
              color: "white",
              fontSize: "13px",
              lineHeight: "48px",
              fontWeight: "200px",
            }}
          >
            Easily search and analyze your logs at any scale,and comply with
            lawful monitoring mandates.
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              lineHeight: "40px",
              marginTop: "10px",
            }}
          >
            <p className="section-content">
              Collect, aggregate, store, parse and analyse very large volumes of
              network flow records generated by routers to meet the lawful
              logging requirements of governments.
            </p>
          </div>

          <div>
            <Button
              className="demo-button"
              component={Link}
              to="/Demo"
              variant="contained"
              endIcon={demoHoverIcon}
              style={{
                backgroundColor: "#0047B3",
                fontWeight: 200,
                color: "#FFFFFF",
                fontWeight: 600,
                padding: "14px 26px",
                fontSize: "14px",
                borderRadius: "39px",
              }}
              onMouseOver={() => setDemoHoverIcon(<ArrowForwardIcon />)}
              onMouseLeave={() => setDemoHoverIcon(<ArrowForwardIosIcon />)}
            >
              FREE DEMO
            </Button>
            <Button
              endIcon={startedHoverIcon}
              className="get-started_btn"
              component={Link}
              to="/Pricing"
              variant="contained"
              style={{
                backgroundColor: "#FFFFFF",
                fontWeight: 200,
                color: "#00327E",
                borderRadius: "39px",
                marginLeft: "20px",
                fontSize: "14px",

                fontWeight: 600,
                padding: "14px 26px",
                fontSize: "14px",
                borderRadius: "39px",
              }}
              onMouseOver={() => setStartedHoverIcon(<ArrowForwardIcon />)}
              onMouseLeave={() => setStartedHoverIcon(<ArrowForwardIosIcon />)}
            >
              GET STARTED
            </Button>
          </div>
        </div>
        <div className="graph-image">
          <img src={Graph} />
        </div>
      </div>
    </section>
  );
}
