import React from "react";
import "./Cta.css";
import Logos from "../../assets/clients/logos.png";
import MadeIndia from "../../assets/clients/madein_India.png";
import { Grid } from "@material-ui/core";
import TitleBackground from "./TitleBackground";

export default function Cta() {
  return (
    <section className="section-cta">
      <div className="cta-container">
        <div style={{ width: "45%" }}>
          <TitleBackground
            title={
              <h1 className="section-cta-title">
                Trusted by leading companies
              </h1>
            }
          ></TitleBackground>
          <p className="cta-desc">
            Companies of all sizes trust Keylong to manage log data efficiently
            and comply with regulatory demands.
          </p>
        </div>
        <div>
          <img src={Logos} className="cta-img" alt="section bg" />
        </div>
      </div>

      <div></div>
      {/* ....................Cts Section Ended here...!............. */}

      <div className="cta-container">
        <div style={{ width: "45%" }}>
          <TitleBackground
            title={<h1 className="section-cta-title">Made in India</h1>}
          />

          <p className="cta-desc">
            Keylong is proudly made in India. It qualifies for key government
            projects requiring for storage of user data for one year to ensure
            compliance with legal provisions as mandated by the Department of
            Telecommunications (DoT), Ministry of Electronics & Information
            Technology. With the ability to record, track, and decode illegal
            activities over the Internet, the Keylong can be useful for the
            police, NIA, and other law enforcement agencies.
          </p>
        </div>
        <div>
          <img src={MadeIndia} className="cta-imgs" alt="section bg" />
        </div>
      </div>

      {/* .........................plans Section................ */}
      <div
        style={{
          display: "flex",
          allignItems: "center",
          justifyContent: "center",
          marginTop: "70px",
        }}
      >
        <div className="plans-section">
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ padding: "30px 30px" }}
          >
            <Grid item xs={5}>
              <h1 style={{ color: "#00327E" }}>Stay up to date</h1>

              <p style={{ color: "#00327E", fontSize: "14px" }}>
                Subscribe to Monthly Newsletter
              </p>
            </Grid>
            <Grid item xs={7}>
              <div className="plans-outer-box">
                <form class="search-wrapper cf">
                  <input type="text" placeholder="Enter your e-mail" required />
                  <button type="submit" className="btn-get-started">
                    Get Started
                  </button>
                </form>
              </div>

              <div
                style={{
                  justifyContent: "right",
                  alignItems: "right",
                  marginLeft: "202px",
                }}
              >
                <p
                  style={{
                    color: "#00327E",
                    fontSize: "10px",
                    lineHeight: "15px",
                    marginTop: "12px",
                  }}
                >
                  *By clicking 'SUBSCRIBE', you agree to processing of personal
                  data according to the{" "}
                  <span style={{ color: "black" }}>Privacy Policy.</span>
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
}
