import React from 'react';
import './App.css';
import MainRoute from "./components/routes/MainRoute";

function App() {
  return (
    <div className="App">
      <MainRoute/>
    </div>
  );
}

export default App;
