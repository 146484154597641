import React from "react";
import "./Clients.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
export default function Clients() {
  const [startedHoverIcon, setStartedHoverIcon] = React.useState(
    <ArrowForwardIosIcon />
  );
  return (
    <section className="section-client">
      <div
        className="multiplans-section"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h1 style={{ color: "#00327E" }}>Multiple Plans</h1>

        <Button
          className="demo-button"
          variant="contained"
          endIcon={startedHoverIcon}
          to="/Pricing"
          component={Link}
          style={{
            backgroundColor: "#0047B3",
            fontWeight: 600,
            padding: "14px 26px",

            fontSize: "12px",
            color: "#FFFFFF",

            borderRadius: "39px",
            marginLeft: "20px",
          }}
          onMouseOver={() => setStartedHoverIcon(<ArrowForwardIcon />)}
          onMouseLeave={() => setStartedHoverIcon(<ArrowForwardIosIcon />)}
        >
          READY TO GET STARTED
        </Button>
      </div>
    </section>
  );
}
