import Button from "@mui/material/Button";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./Pricing.css";
import { Grid } from "@material-ui/core";
import TitleBackground from "../homepage/TitleBackground";
export default function Pricing2() {
  return (
    <div className="pricing-background">
      <div>
        <TitleBackground
          title={
            <h2 className="pricing-title">
              Scalable pricing for
              <br /> every budget
            </h2>
          }
        />
        <p className="pricing-description">
          Explore Keylong pricing options that align with your business needs.
          <br />
          Our goal is to help you get more value from your data. The Keylong
          <br />
          price depends on no. of log sources, ingestion rate per day, log
          <br />
          storage per day, query per month, retention duration, and HA.
          <br />
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <p style={{ fontSize: "20px", color: "#FFFFFF", fontWeight: "100" }}>
            Send us your requirements with the above details at
            <span
              style={{
                fontSize: "20px",
                color: "#FFFFFF",
                fontWeight: "600",
                margin: "6px",
              }}
            >
              marketing@inventum.net
            </span>
            <br />
            our team will share the quote with you soon.
          </p>
        </div>
      </div>
    </div>
  );
}
