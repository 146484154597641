import React from "react";
import logo from "../../../../assets/logo.png";
import Indiaflag from "../../../../assets/indiaflag.png";
import { Link } from "react-router-dom";
import "./Footer.css";
import FacebookIcon from "../../../../assets/socialMidea/feacbook.png";
import TwitterIcon from "../../../../assets/socialMidea/twiter.png";
import InstagramIcon from "../../../../assets/socialMidea/insta.png";
import LinkedInIcon from "../../../../assets/socialMidea/linkedin.png";
import YouTubeIcon from "../../../../assets/socialMidea/youtube.png";

import CopyrightIcon from "@material-ui/icons/Copyright";
import { Grid } from "@material-ui/core";

export default function Footer() {
  return (
    <footer className="frontend-footer">
      <div className="container">
        <div className="footer-info">
          <img alt="Logo" className="footer-logo" src={logo} />

          <p className="footer-txt">Keylong brand is owned by Inventum </p>
          <p className="footer-txt">which is a leading provider of fixed & </p>
          <p className="footer-txt">wireless network technology.</p>
        </div>
        <div className="footer-links">
          <h6>Quick Links</h6>

          <div
            style={{
              maxWidth: "46%",
              flexBasis: "50%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ul className="nav-links">
                  <li>
                    <Link to="/Products">Features</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>

                  <li>
                    <Link to="/demo">Demo</Link>
                  </li>
                </ul>
              </Grid>

              <Grid item xs={6}>
                <ul className="nav-links">
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  {/* <li>
                    <Link to="/login">Login</Link>
                  </li> */}
                </ul>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="social-links">
          <h6>Connect With us</h6>
          <ul className="social-link">
            <li>
              <a
                href="https://www.facebook.com/inventum.net/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={FacebookIcon} height="40px" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/inventum_tech "
                rel="noreferrer"
                target="_blank"
              >
                <img src={TwitterIcon} height="40" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCLVAiXshH3EQ_bMaBZIt9cQ/featured?view_as=subscriber https://www.youtube.com/channel/UCLVAiXshH3EQ_bMaBZIt9cQ/featured?view_as=subscriber"
                rel="noreferrer"
                target="_blank"
              >
                <img src={YouTubeIcon} height="40" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/inventum.tech/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={InstagramIcon} height="40px" />
              </a>
            </li>
            <li>
              <a
                href="	https://in.linkedin.com/company/inventum "
                rel="noreferrer"
                target="_blank"
              >
                <img src={LinkedInIcon} height="30px" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p>
          Copyright <CopyrightIcon /> Inventum Technologies Pvt. Ltd. All Rights
          Reserved
        </p>
      </div>
      <div className="india-flag">
        <img src={Indiaflag} alt="indiaflag" height="40px" />
      </div>
    </footer>
  );
}
