import React, { useState, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import logo from "../../../../assets/keylonglogo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@material-ui/core/Button";
export default function Navbar() {
  const [clicked, setClicked] = useState(false);
  const pathName = window.location.pathname;
  const handleclick = useCallback(
    (e) => {
      setClicked(!clicked);
    },
    [clicked]
  );

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/">
          <img alt="Logo1" src={logo} />
        </Link>
        <div className="menu-icon" onClick={handleclick}>
          {clicked ? <CloseIcon /> : <MenuIcon />}
        </div>
      </div>
      <div
        className={
          clicked ? "navbar-menus navbar-mobile active" : "navbar-menus"
        }
      >
        <ul className="nav-links">
          <li className={pathName === "/Products" && "active"}>
            <Link to="/Products" onClick={handleclick}>
              Feature
            </Link>
          </li>
          <li className={pathName === "/pricing" && "active"}>
            <Link to="/pricing" onClick={handleclick}>
              Pricing
            </Link>
          </li>

          <li className={pathName === "/Support" && "active"}>
            <Link to="/Support" onClick={handleclick}>
              Support
            </Link>
          </li>
          {/* <li className={pathName === "/contact" && "active"}>
            <Link to="/contact" onClick={handleclick}>
              Contact
            </Link>
          </li> */}
          <li className={pathName === "/demo" && "active"}>
            <Link to="/demo" onClick={handleclick}>
              Demo
            </Link>
          </li>

          <li>
            <Button
              component={Link}
              onClick={handleclick}
              to="/contact"
              style={{
                backgroundColor: "#0047B3",
                borderRadius: "20px",
                fontSize: "10px",
                padding: "12px",

                fontSize: "14px",
              }}
            >
              Contact Us
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  );
}
