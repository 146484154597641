import React, { useState, useCallback } from "react";
import "./Login.css";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import validator from "validator";
import { Grid, TextField } from "@mui/material";
import Userimg from "../../assets/login_user.png";

export default function Login() {
  const [form_data, setFormData] = useState({
    user_name: "",
    password: "",
  });
  const [errors, setError] = useState({
    sub_domain: "",
    formError: "",
  });

  /**
   * Function to add the input data to state
   */

  const onHandleInputChange = useCallback(
    (e) => {
      setFormData({ ...form_data, [e.target.name]: e.target.value });
    },
    [form_data]
  );

  /**
   * Form Validation function
   */

  const formValidation = () => {
    let form_error = "";
    var formHasError = false;

    if (
      form_data.user_name === "" ||
      validator.isEmpty(form_data.user_name, { ignore_whitespace: true }) ===
        true
    ) {
      formHasError = true;
      form_error = "Please enter valid userName!";
    }

    setError({ ...errors, user_name: form_error });

    return formHasError;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    if (formValidation() === false) {
      var myHeaders = new Headers();

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        credentials: "include",
      };

      fetch(
        process.env.REACT_APP_Host +
          "/" +
          process.env.REACT_APP_TenantCheck +
          form_data.sub_domain,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            let tenant_url =
              process.env.REACT_APP_Protocol +
              "://" +
              form_data.sub_domain +
              "." +
              process.env.REACT_APP_domain;
            window.location.assign(tenant_url);
          } else {
            setError({
              ...errors,
              formError: "Please Enter Valid Sub-Domain!",
            });
          }
        });
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={6}>
        <section className="account-login">
          <div className="backg-section">
            <h4 className="section-wlm-title">Welcome ! Back</h4>

            <img
              className="user-profile"
              src={Userimg}
              height="200px"
              width="200px"
              style={{
                position: "absolute",
                justifyContent: "left",
                position: "absolute",
                width: "178px",
                height: "242px",
                bottom: "275px",
                left: "224px",
              }}
            />
          </div>
        </section>
      </Grid>
      <Grid item xs={6}>
        <div className="account-login-form-container">
          <div className="container">
            <h3 className="section-title">Sign In to Your Account</h3>

            {errors.formError ? (
              <div className="alert alert-danger">{errors.formError}</div>
            ) : (
              ""
            )}
            <Box
              component="form"
              data-testid="form"
              className="form-box"
              noValidate
              autoComplete="off"
              onSubmit={(e) => formSubmit(e)}
            >
              <TextField
                style={{
                  backgroundColor: "rgb(236, 242, 255);",
                  border: "1px solid rgb(236, 242, 255);",
                  borderRadius: " 4px",
                }}
                errorText={errors.user_name}
                label={"UserName"}
                id={"filled-user_name"}
                inputTestId={"filled-user_name"}
                name={"user_name"}
                variant={"filled"}
                requiredInput={true}
                onChangeHandler={onHandleInputChange}
              />
              <TextField
                style={{
                  backgroundColor: "rgb(236, 242, 255);",
                  border: "1px solid rgb(236, 242, 255);",
                  borderRadius: " 4px",
                }}
                errorText={errors.password}
                label={"Password"}
                id={"filled-user_name"}
                inputTestId={"filled-user_name"}
                name={"user_name"}
                variant={"filled"}
                requiredInput={true}
                onChangeHandler={onHandleInputChange}
              />
              <p className="account-link">
                Create an account? <Link to="/register">Sign Up </Link>
              </p>
              <div className="signin-btn">
                <Button
                  onClick={(e) => formSubmit(e)}
                  type="submit"
                  style={{
                    backgroundColor: " #0047B3",
                    color: "white",
                    borderRadius: "30px",
                    fontSize: "10px",
                    fontWeight: "600px",
                    padding: "8px 27px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    fontSize: "14px",
                  }}
                >
                  Sign In
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
