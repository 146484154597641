import React, { useState, useEffect } from "react";
import FrontHeader from "../canvas/frontend/header/Header";
import FrontFooter from "../canvas/frontend/footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FrontHome from "../canvas/frontend/body/wrapper/Home";
import Contact from "../contact/Contact";
import Login from "../login/Login";
import Pricing2 from "../pricing/Pricing2";
import Support from "../support/support";
import Demo from "../demo/demo";
import Products from "../homepage/Products";

export default function MainRoute() {
  const [isAuthenticated, setAuthencticated] = useState(false);
  const [showSidebar, showHideSidebar] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  let host = window.location.host;
  let tenant = host.substring(
    0,
    host.indexOf("." + process.env.REACT_APP_domain)
  );

  useEffect(() => {
    if (tenant.length && isAuthenticated === false) {
      // checkLogin(tenant);
    }
  }, []);

  let myHeaders = new Headers();

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    credentials: "include",
  };

  const checkLogin = async (tenantName) => {
    await fetch(
      process.env.REACT_APP_Protocol +
        "://" +
        tenantName +
        "." +
        process.env.REACT_APP_domain +
        "/" +
        process.env.REACT_APP_CheckLogin,
      requestOptions
    ).then((response) => {
      if (response.status === 401) {
        let tenant_login_url =
          process.env.REACT_APP_Protocol +
          "://" +
          tenantName +
          "." +
          process.env.REACT_APP_domain +
          "/" +
          process.env.REACT_APP_Authorization +
          tenantName;
        window.location.assign(tenant_login_url);
      } else if (response.status === 400) {
        window.location.assign(process.env.REACT_APP_Host);
      } else {
        setAuthencticated(true);
        getProfile(tenantName);
      }
    });
  };

  const getProfile = async (tenantName) => {
    await fetch(
      process.env.REACT_APP_Protocol +
        "://" +
        tenantName +
        "." +
        process.env.REACT_APP_domain +
        "/" +
        process.env.REACT_APP_Profile,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setUserProfile({
          first_name: result.first_name,
          last_name: result.last_name,
          email: result.email,
        });
      });
  };

  const showHideSidebarHandler = () => {
    showHideSidebar(!showSidebar);
  };

  const geSidebarClassName = () =>
    showSidebar === true
      ? "main-content collapse-out"
      : "main-content collapse-in";

  return (
    <>
      <Router>
        {isAuthenticated === true ? (
          <>
            <div className={geSidebarClassName()}></div>
          </>
        ) : (
          <>
            <FrontHeader />
            <div style={{ height: "30px" }}></div>
            <div className="main-content">
              <Routes>
                <Route path="/" element={<FrontHome />} />
                <Route path="/pricing" element={<Pricing2 />} />
                <Route path="/support" element={<Support />} />
                <Route path="/Products" element={<Products />} />

                <Route path="/services/:serviceName" element={<FrontHome />} />
                <Route path="/contact" element={<Contact />} />
                <Route
                  path="/login"
                  element={
                    <Login setAuthenticatedStatusCallBack={setAuthencticated} />
                  }
                />
                <Route path="/demo" element={<Demo />} />
              </Routes>
            </div>
            <FrontFooter />
          </>
        )}
      </Router>
    </>
  );
}
