import React, { useEffect, useRef } from "react";
import Intro from "../../../../homepage/Intro";
import Products from "../../../../homepage/Products";
import Clients from "../../../../homepage/Clients";
import Cta from "../../../../homepage/Cta";
import Services from "../../../../services/Services";
import Overview from "../../../../homepage/Overview";
import Infodetails from "../../../../homepage/Infodetails";
import { useParams } from "react-router-dom";
import { PanoramaFishEyeRounded } from "@material-ui/icons";

export default function Home() {
  const startRef = useRef();
  const myRef = useRef();
  const params = useParams();
  const { serviceName } = params;
  document.title = "Keylong";
  if (serviceName === "servicePage") {
    myRef.current?.scrollIntoView();
  } else {
    startRef.current?.scrollIntoView();
  }
  useEffect(() => {
    if (serviceName === "servicePage") {
      myRef.current?.scrollIntoView();
    } else {
      startRef.current?.scrollIntoView();
    }
  }, []);
  return (
    <>
      <div ref={startRef}>
        <Intro />
      </div>
      <Overview />
      <Products />
      <div ref={myRef}>
        <Services />
      </div>
      <Infodetails />
      <Clients />
      <Cta />
    </>
  );
}
